import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import track, { TrackingPropType } from 'react-tracking'
import GlobalLayout from 'components/GlobalLayout'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import states from 'utils/states'
import Loader from 'components/Loader'
import { FONT_SIZES, SPACERS } from 'utils/styleHelpers'
import DispensaryListTemplateBase from 'templates/dispensaryListingBase'
import { dispensaryActions } from 'wrFirebase'
import toSlugCase from 'to-slug-case'
import { stampTime, eventDispatch } from 'utils/tracker'
import useGeolocation from 'hooks/useGeolocation'
import { SessionContext } from 'components/AuthContext'
const { getDispensariesByGeo } = dispensaryActions

const StateListWrapper = styled(Flex)`
	text-align: center;
	font-size: ${FONT_SIZES.H2};
`
const StateListBox = styled(Box)`
	font-size: ${FONT_SIZES.H5};
	margin: ${SPACERS.M};
`

function DispensaryPage({ location, pageContext }) {
	const [dispensaries, setDispensaries] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const geolocation = useGeolocation()
	const { userLocation, setUserLocation } = useContext(SessionContext)

	useEffect(() => {
		const fetchData = async () => {
			if (geolocation.error) {
				console.log('error?')
				setIsLoading(false)
				return
			}
			try {
				const { latitude, longitude } = geolocation
				const userDispensaries = await getDispensariesByGeo({ latitude, longitude, radius: 32.1869 })
				if (!geolocation.fetching) {
					setDispensaries(userDispensaries)
					setUserLocation([latitude, longitude])
					setIsLoading(false)
				}
			} catch (e) {
				setIsLoading(false)
				console.log(e.message)
			}
		}
		fetchData()
	}, [geolocation])

	return (
		<>
			{!isLoading ? (
				<>
					{!geolocation.error ? (
						<DispensaryListTemplateBase
							defaultCenter={[geolocation.latitude, geolocation.longitude]}
							defaultMapZoom={12}
							dispensaries={dispensaries}
							entities={states.map(s => ({
								name: s.fullName,
								url: `/dispensaries/${toSlugCase(s.abbr)}`,
							}))}
							entityListTitle={`Dispensaries By State`}
							location={location}
							pageContext={pageContext}
							pageTitle={`Find Marijauna Dispensaries in the United States`}
							pageHeader={`Dispensaries Near You`}
						/>
					) : (
						<GlobalLayout pageTitle="Find Marijuana Dispensaries in the United States" location={location}>
							<Flex flexDirection="column">
								<Box>
									<h1
										css={css`
											text-transform: uppercase;
										`}
									>
										Find Marijuana Dispensaries in the United States
									</h1>
								</Box>
								<Box>
									<StateListWrapper alignItems="center" flexDirection="column" flexWrap="no-wrap">
										{states.map(state => (
											<StateListBox key={state.abbr} py={2}>
												<Link to={`/dispensaries/${state.abbr.toLowerCase()}`}>
													{state.fullName}
												</Link>
											</StateListBox>
										))}
									</StateListWrapper>
								</Box>
							</Flex>
						</GlobalLayout>
					)}
				</>
			) : (
				<Loader />
			)}
		</>
	)
}

DispensaryPage.propTypes = {
	location: PropTypes.object,
	pageContext: PropTypes.object,
	tracking: TrackingPropType,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(DispensaryPage)
