import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/emotion'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import track, { TrackingPropType } from 'react-tracking'
import { Star } from 'components/Iconography'
import LinkWrapper from 'components/LinkWrapper'
import StarRating from 'components/StarRating'
import { DispensaryLocationIcon } from 'components/Iconography'
import { scrollTo } from 'utils/browserHelpers'
import { COLORS, FONT_SIZES } from 'utils/styleHelpers'
import { getLink } from 'utils/urlHelpers'
import { convertToNumberingScheme } from 'utils/stringUtils'
import { stampTime, eventDispatch } from 'utils/tracker'

import { EVENT_TYPES, TRACKING_ACTIONS, NODE_TYPES, DISPENSARY_TABS } from 'utils/constants'

import {
	ActionButtonContainer,
	ActionButtonWrapper,
	DispensaryContainer,
	DispensaryCardWrapper,
	DispensaryDetails,
	DispensaryLocationIconWrapper,
	DispensaryName,
	DispensaryNoRatings,
	DispensaryRatings,
	DispensaryDetailsBox,
	ListItemWrapper,
} from './styles'
import { SessionContext } from 'components/AuthContext'

function DispensaryCard({
	dispensary,
	index = null,
	isSelected = false,
	location,
	setSelectedDispensary,
	setIsShowingMap,
	style,
	tracking,
}) {
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const { address1, address2, averageRating, city, id, name, phone, state, totalReviews, zip } = dispensary
	const gMapsLink = `https://www.google.com/maps/search/?api=1&query=${name}+${address1}+${address2}+${state}`
	const pinIndex = index || index === 0 ? index + 1 : null // we click on a pin from the list if not null
	const clickPin = () => {
		if (pinIndex) {
			scrollTo(0)
			setSelectedDispensary({ ...dispensary, pinIndex: index + 1 })
			setIsShowingMap(true)
		}
	}
	const trackingData = {
		nodeID: id,
		nodeType: NODE_TYPES.DISPENSARIES,
		nodeState: state,
		nodeCity: city,
		nodeZip: zip,
		nodeLabel: name,
		sessionID,
		uid,
		userIp,
		userLocation,
		version,
	}

	return (
		<DispensaryCardWrapper flexDirection="column" isSelected={isSelected}>
			<Box>
				<ListItemWrapper alignItems="center" justifyContent="space-between">
					<Box>
						<DispensaryContainer>
							<DispensaryDetailsBox>
								<LinkWrapper
									to={getLink(dispensary, NODE_TYPES.DISPENSARIES)}
									tracker={{
										action: TRACKING_ACTIONS.MAP_SELECT_ACTION,
										event: EVENT_TYPES.WR_DISPENSARY_MAP_SELECT,
										...trackingData,
									}}
								>
									<DispensaryName lines={1} ellipsis={'...'}>
										{name}
									</DispensaryName>
									{totalReviews > 0 ? (
										<DispensaryRatings>
											<Flex flexDirection="row" alignItems="center">
												<Box>
													<StarRating
														emptyStarColor={COLORS.MEDIUM_LIGHT_GRAY}
														name="reviewRating"
														renderStarIcon={Star({
															fontSize: FONT_SIZES.H5,
														})}
														starColor={COLORS.YELLOW}
														starCount={5}
														starRatingId={id}
														isEditing={false}
														value={averageRating || 0}
													/>
												</Box>
												<Box mx="1">
													{`${
														averageRating ? averageRating.toFixed(1) : 0
													} Stars out of ${totalReviews} Review${
														totalReviews > 1 ? `s` : ''
													}`}
												</Box>
											</Flex>
										</DispensaryRatings>
									) : (
										<DispensaryNoRatings>Be the first to add a review!</DispensaryNoRatings>
									)}
									<DispensaryDetails>
										{address1} {city}, {state} {zip}
									</DispensaryDetails>
								</LinkWrapper>
							</DispensaryDetailsBox>
						</DispensaryContainer>
					</Box>
					{(pinIndex || dispensary.pinIndex) && (
						<DispensaryLocationIconWrapper
							onClick={clickPin}
							onKeyPress={clickPin}
							role="link"
							tabIndex={-1}
						>
							<DispensaryLocationIcon
								text={convertToNumberingScheme(pinIndex || dispensary.pinIndex)}
								color={COLORS.GREEN}
							/>
						</DispensaryLocationIconWrapper>
					)}
				</ListItemWrapper>
			</Box>
			<Box>
				<ActionButtonWrapper flexDirection="row">
					<a
						href={`tel:${phone}`}
						css={css`
							&:hover {
								text-decoration: none;
							}
						`}
						onClick={() =>
							tracking.trackEvent({
								action: TRACKING_ACTIONS.CALL_ACTION,
								event: EVENT_TYPES.WR_DISPENSARY_CALL,
								...trackingData,
							})
						}
					>
						<ActionButtonContainer>Contact</ActionButtonContainer>
					</a>
					<a
						href={gMapsLink}
						css={css`
							&:hover {
								text-decoration: none;
							}
						`}
						onClick={() =>
							tracking.trackEvent({
								action: TRACKING_ACTIONS.DIRECTIONS_ACTION,
								event: EVENT_TYPES.WR_DISPENSARY_DIRECTIONS,
								...trackingData,
							})
						}
						target="_new"
					>
						<ActionButtonContainer>Directions</ActionButtonContainer>
					</a>
					<Link
						css={css`
							&:hover {
								text-decoration: none;
							}
						`}
						onClick={() =>
							tracking.trackEvent({
								action: TRACKING_ACTIONS.MAP_SELECT_ACTION,
								event: EVENT_TYPES.WR_DISPENSARY_MAP_SELECT,
								...trackingData,
							})
						}
						state={{ activeTabIndex: DISPENSARY_TABS.MENU, scrollTo: true }}
						to={getLink(dispensary, NODE_TYPES.DISPENSARIES)}
					>
						<ActionButtonContainer>View Menu</ActionButtonContainer>
					</Link>
					<Link
						css={css`
							&:hover {
								text-decoration: none;
							}
						`}
						onClick={() =>
							tracking.trackEvent({
								action: TRACKING_ACTIONS.MAP_SELECT_ACTION,
								event: EVENT_TYPES.WR_DISPENSARY_MAP_SELECT,
								...trackingData,
							})
						}
						to={getLink(dispensary, NODE_TYPES.DISPENSARIES)}
					>
						<ActionButtonContainer>Learn More</ActionButtonContainer>
					</Link>
				</ActionButtonWrapper>
			</Box>
		</DispensaryCardWrapper>
	)
}

DispensaryCard.propTypes = {
	dispensary: PropTypes.object,
	location: PropTypes.object.isRequired,
	index: PropTypes.number,
	isSelected: PropTypes.bool,
	setSelectedDispensary: PropTypes.func,
	setIsShowingMap: PropTypes.func,
	style: PropTypes.object,
	tracking: TrackingPropType,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(DispensaryCard)
