import styled from '@emotion/styled'
import { MQ } from 'utils/styleHelpers'

export const NoDisplayMobile = styled.div`
	display: none;
	margin: 0;
	padding: 0;
	${MQ.L} {
		display: contents;
	}
`
export const NoDisplayDesktop = styled.div`
	display: contents;
	margin: 0;
	padding: 0;
	${MQ.L} {
		display: none;
	}
`
