import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { COLORS, FONT_SIZES, MQ, SPACERS, shadeColor2 } from 'utils/styleHelpers'
import { Box, Flex } from '@rebass/emotion'
import Truncate from 'react-truncate'

const actionButtonBase = css`
	align-self: center;
	background-color: ${COLORS.LIGHT_GRAY};
	border: solid 1px ${COLORS.MEDIUM_LIGHT_GRAY};
	border-radius: 5px;
	cursor: pointer;
	flex-shrink: 0;
	padding: 9px;
	margin: 4px;
	text-align: center;
	text-decoration: none !important;
	transition: background-color 0.5s, color 0.5s;
	.dark & {
		background-color: ${shadeColor2(COLORS.BLACK, 0.06)};
		color: ${COLORS.WHITE};
	}
	${MQ.L} {
		padding: ${SPACERS.M};
		margin: ${SPACERS.S};
	}
`

export const ActionButtonContainer = styled.div`
	background-color: ${COLORS.LIGHT_GRAY};
	border: solid 1px ${COLORS.MEDIUM_LIGHT_GRAY};
	border-radius: 5px;
	cursor: pointer;
	flex-shrink: 0;
	font-size: ${FONT_SIZES.H7};
	padding: 9px;
	margin: 4px;
	text-align: center;
	transition: background-color 0.5s, color 0.5s;
	.dark & {
		background-color: ${shadeColor2(COLORS.BLACK, 0.06)};
		color: ${COLORS.WHITE};
	}
	${MQ.L} {
		padding: ${SPACERS.M};
		margin: ${SPACERS.S};
	}
`

export const ActionButton = styled(Link)`
	${actionButtonBase}
`
export const ActionButtonLink = styled.a`
	${actionButtonBase}
`
export const ActionButtonWrapper = styled(Flex)`
	margin-top: ${SPACERS.M};
	margin-bottom: ${SPACERS.S};
`
export const LinkBox = styled(Box)`
	font-size: ${FONT_SIZES.H6};
`

export const CityHeader = styled.h3`
	text-transform: uppercase;
`
export const DispensaryContainer = styled(Flex)`
	width: 100%;
`

export const DispensaryCardWrapper = styled(Flex)`
	background: ${COLORS.WHITE};
	border-radius: 5px;
	transition: background 0.5s, box-shadow 0.5s;
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	padding-left: ${SPACERS.M};
	padding-right: ${SPACERS.M};
	margin-top: ${SPACERS.S};
	margin-bottom: ${SPACERS.S};
	margin-right: ${SPACERS.S};
	margin-left: ${SPACERS.S};
	${({ isSelected }) => isSelected && `box-shadow: 1px 0px 7px 2px  ${COLORS.DARK_GRAY};`}
	${MQ.L} {
		margin-left: ${SPACERS.S};
		margin-right: ${SPACERS.S};
		margin-top: ${SPACERS.M};
		margin-bottom: 0;
		padding: ${SPACERS.M};
	}
	.dark & {
		background-color: ${shadeColor2(COLORS.BLACK, 0.13)};
		${({ isSelected }) => isSelected && `box-shadow: 1px 0px 6px 6px  ${COLORS.MEDIUM_LIGHT_GRAY};`}
	}
`

export const DispensaryDetailsBox = styled(Box)`
	font-size: ${FONT_SIZES.H5};
	width: 100%;
`
export const ListItemWrapper = styled(Flex)``
export const DispensaryLocationIconWrapper = styled(Box)`
	cursor: pointer;
`
export const DispensaryName = styled(Truncate)`
	font-size: ${FONT_SIZES.H5};
	font-weight: bold;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
`
export const DispensaryRatings = styled(Box)`
	font-size: ${FONT_SIZES.H5};
	color: ${COLORS.YELLOW};
	font-weight: bold;
`
export const DispensaryNoRatings = styled(Box)`
	font-size: ${FONT_SIZES.H5};
	color: ${COLORS.YELLOW};
	font-weight: bold;
`

export const DispensaryDetails = styled(Box)`
	color: ${COLORS.BLACK};
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
`
