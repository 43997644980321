import styled from '@emotion/styled'
import { COLORS, FONT_SIZES, MQ, SPACERS, shadeColor2 } from 'utils/styleHelpers'
import { Box, Card, Flex } from '@rebass/emotion'

export const CancelButtonWrapper = styled.div`
	left: 5px;
	position: absolute;
`
export const CancelButton = styled.button`
	font-size: 10px;
	background-color: ${COLORS.WHITE};
	border: none;
	font-weight: bold;
	color: ${COLORS.BLACK};
	border: 0px;
	cursor: pointer;
`
export const MapCard = styled(Card)`
	background-color: ${COLORS.WHITE};
	font-size: ${FONT_SIZES.H7};
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`

export const MapFooter = styled(Flex)`
	postion: fixed;
	bottom: 0;
	background: ${COLORS.WHITE};
	padding: ${SPACERS.L};
	height: 15vh;
	margin: 0;
	transition: background 0.5s;
	.dark & {
		background: ${shadeColor2(COLORS.BLACK, 0.06)};
	}
`

export const DispensaryLink = styled.div`
	color: ${COLORS.GREEN};
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`
export const ZoomBox = styled(Box)`
	background: ${COLORS.GREEN};
	border-radius: 50px;
	margin-left: ${SPACERS.S};
	margin-right: ${SPACERS.S};
	padding-top: 15px;
	padding-left: 15px;
	cursor: pointer;
	height: 50px;
	width: 50px;
`
export const SliderBox = styled(Box)`
	margin-top: ${SPACERS.M};
	margin-bottom: ${SPACERS.M};
	flex-basis: 60%;
`
export const ContentBox = styled(Box)`
	padding-top: ${SPACERS.M};
`
