import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from 'utils/styleHelpers'
import { DispensaryLocationIcon } from 'components/Iconography'

const imageOffset = {
	left: 15,
	top: 31,
}

function Marker({ anchor, left, onClick, onContextMenu, onMouseOver, onMouseOut, payload, top, text = null }) {
	// what do you expect to get back with the event
	const eventParameters = event => {
		return {
			event,
			anchor,
			payload,
		}
	}
	// delegators

	const handleClick = event => {
		onClick(eventParameters(event))
		return true
	}

	const handleContextMenu = event => {
		onContextMenu(eventParameters(event))
	}

	const handleMouseOver = event => {
		onMouseOver(eventParameters(event))
	}

	const handleMouseOut = event => {
		onMouseOut(eventParameters(event))
	}

	const style = {
		position: 'absolute',
		transform: `translate(${left - imageOffset.left}px, ${top - imageOffset.top}px)`,
		cursor: onClick ? 'pointer' : 'default',
	}
	return (
		<div
			onBlur={handleMouseOut}
			onClick={handleClick}
			onContextMenu={handleContextMenu}
			onFocus={handleMouseOver}
			onKeyDown={handleClick}
			onMouseOut={handleMouseOut}
			onMouseOver={handleMouseOver}
			role="link"
			style={style}
			tabIndex="0"
		>
			<DispensaryLocationIcon color={COLORS.GREEN} text={text} />
		</div>
	)
}

Marker.propTypes = {
	anchor: PropTypes.array.isRequired,
	isHovered: PropTypes.bool,
	latLngToPixel: PropTypes.func,
	left: PropTypes.number,
	onClick: PropTypes.func,
	onContextMenu: PropTypes.func,
	onMouseOut: PropTypes.func,
	onMouseOver: PropTypes.func,
	payload: PropTypes.any,
	pixelToLatLng: PropTypes.func,
	text: PropTypes.string,
	top: PropTypes.number,
}

Marker.defaultProps = {
	onClick: () => true,
	onContextMenu: () => true,
	onMouseOut: () => true,
	onMouseOver: () => true,
}
export default Marker
