import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@rebass/emotion'
import { COLORS, shadeColor2 } from 'utils/styleHelpers'
import { scrollTo } from 'utils/browserHelpers'

import { IoIosClose } from 'react-icons/io'
import track, { TrackingPropType } from 'react-tracking'

import { ResetIcon } from 'components/Iconography'
import GlobalLayout from 'components/GlobalLayout'
import DispensaryCard from 'components/DispensaryCard'
import { DispensaryLocator } from 'components/Iconography'
import DispensaryMap from 'components/DispensaryMap'
import DispensaryMapList from 'components/DispensaryMapList'
import { NoDisplayDesktop } from 'components/NoDisplay'
import StickyFooter from 'components/StickyFooter'
import useDebounce from 'hooks/useDebounce'
import { stampTime, eventDispatch } from 'utils/tracker'
import { useCurrentBreakpointName } from 'react-socks'
import {
	DispensaryMapListWrapper,
	DispensaryHeader,
	DispensaryDetailPanel,
	DispensaryDetailWrapper,
	FilterInput,
	FilterInputDetails,
	FilterInputSummary,
	ListBox,
	ListBoxWrapper,
	ListingContainer,
	ListHeader,
	PanelCloseBox,
	PanelCloseButton,
	ResetButton,
	TemplateWrapper,
	MapBox,
	MapToggleBoxLeft,
	MapToggleBoxRight,
	MapToggleWrapper,
	NoDispensaries,
} from './styles'

function DispensaryListingTemplateBase({
	defaultCenter,
	defaultMapZoom,
	dispensaries,
	entityListTitle = '',
	entities = [],
	location,
	pageContext,
	pageTitle,
	pageHeader,
	tracking,
}) {
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'

	const [dispensary, setDispensary] = useState(null)
	const [isShowingMap, setIsShowingMap] = useState(!isMobile)
	const [filterText, setFilterText] = useState('')
	const [dispensaryList, setDispensaryList] = useState([])
	const debounceFilterText = useDebounce(filterText, 200)
	const filterInputDetailsRef = useRef(null)
	const filterInputRef = useRef(null)
	const [isFilterOpen, setIsFilterOpen] = useState(false)

	useLayoutEffect(() => {
		setDispensaryList(dispensaries)
	}, [dispensaries])
	useEffect(() => {
		if (isFilterOpen) {
			filterInputRef.current.focus()
		}
	}, [isFilterOpen])
	useEffect(() => {
		let newList = dispensaries
		if (debounceFilterText !== '') {
			const testText = debounceFilterText
				.toString()
				.toLowerCase()
				.trim()
			newList = dispensaries.filter(d => {
				const { name = '', address1 = '', city = '', zip = '' } = d
				return (
					(name && name !== '' && name.toLowerCase().includes(testText)) ||
					(address1 && address1 !== '' && address1.toLowerCase().includes(testText)) ||
					(city && city !== '' && city.toLowerCase().includes(testText)) ||
					(zip && zip && zip !== '' && zip.toLowerCase().includes(testText)) ||
					false
				)
			})
		}
		setDispensaryList(newList)
	}, [debounceFilterText])
	const onToggle = () => {
		setIsFilterOpen(!isFilterOpen)
	}
	return (
		<GlobalLayout
			pageTitle={pageTitle}
			location={location}
			backgroundColor={COLORS.LIGHT_GRAY}
			isShowingFooter={false}
		>
			<TemplateWrapper flexDirection="column" justifyContent="center">
				<Box>
					<ListingContainer
						flexDirection={['column', 'row-reverse']}
						justifyContent={isMobile ? 'space-between' : 'center'}
					>
						<MapBox isShowingMap={isShowingMap}>
							{isShowingMap && (
								<DispensaryMap
									defaultCenter={
										!dispensary
											? defaultCenter
											: [
												dispensary.geoLocation._latitude + 0.003,
												dispensary.geoLocation._longitude,
											]
									}
									defaultZoom={defaultMapZoom}
									dispensaries={dispensaryList}
									selectedDispensary={dispensary ? { ...dispensary } : null}
									setSelectedDispensary={setDispensary}
								/>
							)}
						</MapBox>
						<ListBox
							alignSelf={dispensaries.length === 0 ? 'flex-start' : 'center'}
							isShowingMap={isShowingMap}
							isMobile={isMobile}
						>
							<ListBoxWrapper flexDirection="column">
								{(!isShowingMap || !isMobile) && (
									<>
										<ListHeader top={124}>
											<Box>
												<DispensaryHeader id="dispensaryHeader">
													{pageHeader}
												</DispensaryHeader>
											</Box>
											<Box>
												<FilterInputDetails>
													<FilterInputSummary
														ref={filterInputDetailsRef}
														onClick={onToggle}
													>
														Filter Search
														</FilterInputSummary>
													<FilterInput
														ref={filterInputRef}
														onChange={e => setFilterText(e.target.value)}
														type="text"
														value={filterText}
														placeholder="Filter by Name, City, or Zip"
													/>
													<ResetButton
														type="reset"
														onClick={() => {
															setFilterText('')
															filterInputRef.current.focus()
														}}
													>
														{ResetIcon}
													</ResetButton>
												</FilterInputDetails>
											</Box>
										</ListHeader>
										{dispensaryList.length > 0 ? (
											<DispensaryMapListWrapper isFilterOpen={isFilterOpen}>
												<DispensaryMapList
													dispensaries={dispensaryList}
													location={location}
													setSelectedDispensary={setDispensary}
													selectedDispensary={dispensary}
													setIsShowingMap={setIsShowingMap}
												/>
											</DispensaryMapListWrapper>
										) : (
												<DispensaryMapListWrapper>
													<NoDispensaries>
														We&#39;re sorry. There are no dispensaries registered with
														Weedrater.com in this state.
													</NoDispensaries>
												</DispensaryMapListWrapper>
											)}
									</>
								)}
							</ListBoxWrapper>
						</ListBox>
						{dispensary ? (
							<NoDisplayDesktop>
								<DispensaryDetailPanel flexDirection="column" justifyContent="flex-end">
									<PanelCloseBox alignSelf="flex-end">
										<PanelCloseButton
											ariaLabel="Close Details"
											type="button"
											onClick={() => {
												setDispensary(null)
												setIsShowingMap(!isShowingMap)
											}}
										>
											<IoIosClose />
										</PanelCloseButton>
									</PanelCloseBox>
									<Box>
										<DispensaryDetailWrapper>
											<DispensaryCard
												backgroundColorDark={shadeColor2(COLORS.BLACK, 0.06)}
												dispensary={dispensary}
												setSelectedDispensary={setDispensary}
												setIsShowingMap={setIsShowingMap}
												location={location}
											/>
										</DispensaryDetailWrapper>
									</Box>
								</DispensaryDetailPanel>
							</NoDisplayDesktop>
						) : (
								<NoDisplayDesktop>
									<StickyFooter verticalHeight="11vh">
										<MapToggleWrapper
											justifyContent="space-between"
											flexDirection="row"
											alignItems="center"
										>
											<MapToggleBoxLeft
												onClick={() => {
													if (!isShowingMap) {
														scrollTo(0)
													}
													setIsShowingMap(!isShowingMap)
												}}
											>
												{isShowingMap ? `View Listings` : `View Map`}
											</MapToggleBoxLeft>
											<MapToggleBoxRight>
												<DispensaryLocator width={50} height={50} />
											</MapToggleBoxRight>
										</MapToggleWrapper>
									</StickyFooter>
								</NoDisplayDesktop>
							)}
					</ListingContainer>
				</Box>
			</TemplateWrapper>
		</GlobalLayout>
	)
}

DispensaryListingTemplateBase.propTypes = {
	defaultCenter: PropTypes.array,
	defaultMapZoom: PropTypes.number,
	dispensaries: PropTypes.array,
	entityListTitle: PropTypes.string,
	entities: PropTypes.array,
	location: PropTypes.object,
	pageContext: PropTypes.object,
	pageTitle: PropTypes.string,
	pageHeader: PropTypes.string,
	tracking: TrackingPropType,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(DispensaryListingTemplateBase)
