import React from 'react'
import PropTypes from 'prop-types'
import DispensaryCard from 'components/DispensaryCard'

function DispensaryListRow({
	dispensary,
	index,
	location,
	rowKey,
	selectedDispensary,
	setIsShowingMap,
	setSelectedDispensary,
	style
}) {
	return (
		<div style={style} key={rowKey}>
			<DispensaryCard
				dispensary={dispensary}
				location={location}
				setSelectedDispensary={setSelectedDispensary}
				isSelected={selectedDispensary ? dispensary.id === selectedDispensary.id : false}
				setIsShowingMap={setIsShowingMap}
				index={index}
			/>
		</div>
	)
}

DispensaryListRow.propTypes = {
	dispensary: PropTypes.object,
	location: PropTypes.object,
	index: PropTypes.number,
	rowKey: PropTypes.string,
	selectedDispensary: PropTypes.object,
	setIsShowingMap: PropTypes.func,
	setSelectedDispensary: PropTypes.func,
	style: PropTypes.object,
}
export default DispensaryListRow
