import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FixedSizeList as List } from 'react-window'
import DispensaryListRow from './DispensaryListRow'
import { sortByKey } from 'utils/arrayHelpers'
import { MQ } from 'utils/styleHelpers'
import { useCurrentBreakpointName } from 'react-socks'

const DispensaryListWrapper = styled.div`
	height: 550px;
	${MQ.L} {
		height: 100vh;
	}
`

function DispensaryMapList({
	dispensaries,
	location,
	setSelectedDispensary,
	selectedDispensary,
	setIsShowingMap,
}) {
	const [dispensariesSorted, setDispensariesSorted] = useState([])
	const [selectedDispensaryIndex, setSelectedDispensaryIndex] = useState(0)
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const rwList = useRef(null)

	useEffect(() => {
		setDispensariesSorted(sortByKey(dispensaries, 'name'))
	}, [dispensaries])

	useEffect(() => {
		if (selectedDispensary) {
			setSelectedDispensaryIndex(
				dispensariesSorted.findIndex(dispensary => dispensary.id === selectedDispensary.id) + 1
			)
		}
	}, [dispensariesSorted, selectedDispensary])

	useEffect(() => {
		if (!isMobile) {
			rwList.current.scrollToItem(selectedDispensaryIndex, 'center')
		}
	}, [isMobile, selectedDispensaryIndex])

	function rowRenderer({ index, style }) {
		return (
			<DispensaryListRow
				key={`key_${index}`}
				index={index}
				location={location}
				dispensary={dispensariesSorted[index]}
				style={style}
				setSelectedDispensary={setSelectedDispensary}
				selectedDispensary={selectedDispensary}
				setIsShowingMap={setIsShowingMap}
			/>
		)
	}
	rowRenderer.propTypes = {
		index: PropTypes.number,
		style: PropTypes.object
	}
	return (
		<DispensaryListWrapper>
			<List
				height={isMobile ? 550 : 760}
				itemSize={isMobile ? 150 : 165}
				itemCount={dispensariesSorted.length}
				ref={rwList}
				scrollTop={30}
				scrollToAlignment="start"
				scrollToIndex={selectedDispensaryIndex}
				width={isMobile ? 375 : 420}
				location={location}
			>
				{rowRenderer}
			</List>
		</DispensaryListWrapper>
	)
}

DispensaryMapList.propTypes = {
	dispensaries: PropTypes.array,
	location: PropTypes.object,
	setSelectedDispensary: PropTypes.func,
	selectedDispensary: PropTypes.object,
	setIsShowingMap: PropTypes.func
}

export default DispensaryMapList
