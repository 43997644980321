import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { COLORS, MQ, SPACERS, FONT_SIZES, shadeColor2 } from 'utils/styleHelpers'
import { Box, Flex } from '@rebass/emotion'
import { InputField } from 'components/FormFields'

const viewMapBoxBase = css`
	font-size: ${FONT_SIZES.H4};
	font-weight: bold;
	color: ${COLORS.GREEN};
`

export const DispensaryDetailPanel = styled(Flex)`
	background: ${COLORS.WHITE};
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
	bottom: 0px;
	position: fixed;
	transition: background 0.5s;
	width: 100%;
	.dark & {
		background: ${shadeColor2(COLORS.BLACK, 0.13)};
	}
`
export const DispensaryDetailWrapper = styled(Box)`
	width: 100%;
	${MQ.L} {
		padding-left: ${SPACERS.L};
		padding-right: ${SPACERS.L};
	}
`
export const DispensaryHeader = styled.h1`
	font-size: ${FONT_SIZES.H3};
	margin-top: 5px;
	margin-bottom: 10px;
	margin-left: 20px;
`
export const DispensaryMapListWrapper = styled(Box)`
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	margin-top: ${({ isFilterOpen }) => (isFilterOpen || typeof isFilterOpen === 'undefined' ? '110px' : '70px')};
	${MQ.L} {
		height: 77vh;
		top: 200px;
	}
`

export const Emoji = styled.span`
	font-size: 30px;
	line-height: 30px;
`

export const ResetButton = styled.button`
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;
	margin: auto;
	outline: 0;
	position: absolute;
	left: 243px;
	top: 69px;
	vertical-align: middle;
	${MQ.L} {
		left: 260px;
		top: 73px;
	}
	.dark & {
		color: white;
	}
`

export const FilterInput = styled(InputField)`
width: 250px;
font - size: ${FONT_SIZES.H7};
	&: not(: valid) ~${ResetButton} {
	display: none;
}
${MQ.L} {
	margin - left: ${SPACERS.S};
}
`

export const FilterInputDetails = styled.details`
	margin-left: ${SPACERS.L};
	margin-right: ${SPACERS.L};
	padding-bottom: ${SPACERS.M};
	summary:: -webkit-details-marker {
		margin-left: ${SPACERS.L};
	}
	& [open] summary ~ * {
		animation: show 0.5s;
	}
	@keyframes show {
		0 % {
			opacity: 0;
		}
		100 % {
			opacity: 1;
		}
	}
`

export const FilterInputSummary = styled.summary`
	cursor: pointer;
	color: ${COLORS.DARK_GRAY};
	font-weight: bold;
	font-size: ${FONT_SIZES.H6};
	overflow: hidden;
	outline: none !important;
	text-transform: uppercase;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.LIGHT_GRAY};
	}
`

export const ListBox = styled(Box)`
	flex: ${({ isShowingMap, isMobile }) => (isShowingMap && isMobile ? '0' : '1')};
	display: ${({ isShowingMap, isMobile }) => (isShowingMap && isMobile ? 'none' : 'flex')};
	opacity: ${({ isShowingMap, isMobile }) => (isShowingMap && isMobile ? '0' : '1')};
	transition: visibility ${({ isShowingMap }) => (isShowingMap ? '0.1s' : '0s')},
		opacity ${({ isShowingMap }) => (isShowingMap ? '0.1s' : '0s')} linear;
	width: 100%;

	${MQ.M} {
		flex-basis: 30%;
		max-width: 430px;
		min-width: 430px;
	}
`
export const ListBoxWrapper = styled(Flex)`
	max-width: inherit;
	min-width: inherit;
	width: inherit;
`
export const ListingContainer = styled(Flex)`
	width: 100%;
`
export const ListHeader = styled(Box)`
	background: ${COLORS.LIGHT_GRAY};
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	overflow-y: scroll;
	overflow-x: hidden;
	position: fixed;
	max-width: inherit;
	min-width: inherit;
	transition: background 0.5s;
	top: 95px;
	width: inherit;
	z-index: 1;
	${MQ.L} {
		padding-top: ${SPACERS.S};
		top: 92px;
	}
	.dark & {
		background: ${COLORS.BLACK};
	}
`
export const MapBox = styled(Box)`
flex: ${({ isShowingMap, isMobile }) => (isShowingMap && !isMobile ? '1' : '0')};
${({ isShowingMap }) => !isShowingMap && `max-height: 0;`}
display: ${({ isShowingMap }) => (isShowingMap ? 'inline' : 'block')};
opacity: ${({ isShowingMap }) => (isShowingMap ? '1' : '0')};
visibility: ${({ isShowingMap }) => (isShowingMap ? 'visible' : 'hidden')};
transition: visibility ${({ isShowingMap }) => (isShowingMap ? '0.1s' : '0.25s')}, opacity ${({ isShowingMap }) =>
	isShowingMap ? '0.1s' : '0.15s'} linear;
height: 70vh;
width: 100vw;

${MQ.M} {
	flex-basis: 70 %;
	margin: 0;
}
`
export const MapToggleBoxLeft = styled(Box)`
${viewMapBoxBase}
margin-left: ${SPACERS.L};
`
export const MapToggleBoxRight = styled(Box)`
${viewMapBoxBase}
margin-right: ${SPACERS.L};
line-height: 1.2;
`

export const MapToggleWrapper = styled(Flex)`
	background-color: ${COLORS.WHITE};
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
	transition: background 0.5s, box - shadow 0.5s;
	width: 100%;
	.dark & {
		background-color: ${shadeColor2(COLORS.BLACK, 0.06)};
		box-shadow: 1px 1px 10px #000;
	}
`

export const NoDispensaries = styled.div`
	background: ${COLORS.GREEN};
	border-radius: 2px;
	color: ${COLORS.WHITE};
	font-weight: 500;
	margin-bottom: ${SPACERS.L};
	padding: ${SPACERS.L};
	text-align: center;
	transition: color 0.5s;
	width: 100%;
	${MQ.M} {
		margin-left: 30px;
		width: 80%;
	}
`

export const PaginatorBox = styled(Box)``

export const PanelCloseBox = styled(Box)`
	flex-basis: 10%;
`

export const PanelCloseButton = styled.button`
	color: ${COLORS.BLACK};
	cursor: pointer;
	display: block;
	border: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	font-size: ${FONT_SIZES.H2};
	height: 33px;
	padding: 0;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.GREEN};
	}
`

export const TemplateWrapper = styled(Flex)`
	padding-top: ${SPACERS.S};
`
