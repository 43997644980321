import { useState, useEffect } from 'react'

const useGeolocation = () => {
	const [state, setState] = useState({
		accuracy: null,
		altitude: null,
		altitudeAccuracy: null,
		error: null,
		fetching: true,
		heading: null,
		latitude: null,
		longitude: null,
		speed: null,
		timestamp: Date.now(),
	})
	let mounted = true
	let watchId

	const onEvent = event => {
		if (mounted) {
			setState({
				accuracy: event.coords.accuracy,
				altitude: event.coords.altitude,
				altitudeAccuracy: event.coords.altitudeAccuracy,
				error: null,
				fetching: false,
				heading: event.coords.heading,
				latitude: event.coords.latitude,
				longitude: event.coords.longitude,
				speed: event.coords.speed,
				timestamp: event.timestamp,
			})
		}
	}

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(onEvent)
		watchId = navigator.geolocation.watchPosition(onEvent, error => {
			console.log(error.message)
			setState({ ...state, error: error, fetching: false })
		})
		return () => {
			mounted = false
			navigator.geolocation.clearWatch(watchId)
		}
	}, [])

	return state
}

export default useGeolocation
