export function scrollTo(scrollPosition = 0) {
	try {
		window.scrollTo({
			top: scrollPosition,
			left: 0,
			behavior: 'smooth',
		})
	} catch (ex) {
		if (typeof window.scrollTo === 'function') {
			window.scrollTo(0, scrollPosition)
		}
	}
}

export function scrollToTopOf(element, scrollOffset = 0) {
	const rect = element.getBoundingClientRect()
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop

	scrollTo(rect.top + scrollTop - scrollOffset)
}
