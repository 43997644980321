import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/emotion'
import Map from 'pigeon-maps'
import { ZoomInIcon, ZoomOutIcon, DispensaryLocator } from 'components/Iconography'
import { MapFooter, ZoomBox } from './styles'
import Marker from 'components/DispensaryMapMarker'
import { NoDisplayMobile } from 'components/NoDisplay'

import { convertToNumberingScheme } from 'utils/stringUtils'
import { COLORS } from 'utils/styleHelpers'
import { stampTime, eventDispatch } from 'utils/tracker'

const selectedZoomLevel = 15

const providers = {
	osm: (x, y, z) => {
		const s = String.fromCharCode(97 + ((x + y + z) % 3))
		return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`
	},
	wikimedia: (x, y, z) => {
		const retina = typeof window !== 'undefined' && window.devicePixelRatio >= 2 ? '@2x' : ''
		return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${retina}.png`
	},
	stamen: (x, y, z) => {
		const retina = typeof window !== 'undefined' && window.devicePixelRatio >= 2 ? '@2x' : ''
		return `https://stamen-tiles.a.ssl.fastly.net/terrain/${z}/${x}/${y}${retina}.jpg`
	},
}

const StamenAttribution = () => (
	<span className="map-attribution">
		Map tiles by <a href="https://stamen.com">Stamen Design</a>, under{' '}
		<a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by{' '}
		<a href="https://openstreetmap.org">OpenStreetMap</a>, under{' '}
		<a href="http://www.openstreetmap.org/copyright">ODbL</a>.
	</span>
)

const WikimediaAttribution = () => (
	<span className="map-attribution">
		Map tiles by{' '}
		<a href="https://foundation.wikimedia.org/w/index.php?title=Maps_Terms_of_Use#Where_does_the_map_data_come_from.3F">
			Wikimedia
		</a>
		. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>
	</span>
)

function DispensaryMap({
	defaultZoom = 6,
	defaultCenter,
	dispensaries,
	isVisible,
	selectedDispensary,
	setSelectedDispensary,
}) {
	const [center, setCenter] = useState(defaultCenter)
	const [zoom, setZoom] = useState(defaultZoom)
	const [provider] = useState('wikimedia')
	const [metaWheelZoom] = useState(false)
	const [twoFingerDrag] = useState(false)
	const [animate] = useState(true)
	const [, setAnimating] = useState(false)
	const [zoomSnap] = useState(false)
	const [mouseEvents] = useState(true)
	const [touchEvents] = useState(true)
	const [minZoom] = useState(1)
	const [maxZoom] = useState(18)

	useEffect(() => {
		setZoom(defaultZoom)
		setCenter(defaultCenter)
	}, [defaultCenter])

	useEffect(() => {
		if (selectedDispensary) {
			setCenter([selectedDispensary.geoLocation._latitude, selectedDispensary.geoLocation._longitude])
			setZoom(selectedZoomLevel)
		}
	}, [selectedDispensary])

	return (
		<>
			<Map
				center={center}
				zoom={zoom}
				provider={providers[provider]}
				onAnimationStart={() => setAnimating(true)}
				onAnimationStop={() => setAnimating(false)}
				onBoundsChanged={({ updatedCenter, updatedZoom }) => {
					setCenter(updatedCenter || center)
					setZoom(updatedZoom || zoom)
				}}
				animate={animate}
				metaWheelZoom={metaWheelZoom}
				twoFingerDrag={twoFingerDrag}
				zoomSnap={zoomSnap}
				mouseEvents={mouseEvents}
				touchEvents={touchEvents}
				minZoom={minZoom}
				maxZoom={maxZoom}
				attribution={
					provider === 'stamen' ? (
						<StamenAttribution />
					) : provider === 'wikimedia' ? (
						<WikimediaAttribution />
					) : null
				}
			>
				{dispensaries.map((d, index) => {
					const { id, geoLocation } = d
					return (
						<Marker
							key={id}
							anchor={[geoLocation._latitude, geoLocation._longitude]}
							onClick={({ event, payload }) => {
								event.preventDefault()
								const element = document.getElementById(id)
								if (element) {
									element.scrollIntoView(true)
								}

								const {
									geoLocation: { _latitude, _longitude },
								} = payload
								setSelectedDispensary({ ...payload, pinIndex: index + 1 })
								setZoom(selectedZoomLevel)
								setCenter([
									typeof window !== 'undefined' && window.matchMedia(matchMedia.L).matches
										? _latitude
										: _latitude + 0.002,
									_longitude,
								])
							}}
							payload={{ ...d }}
							text={convertToNumberingScheme(index + 1)}
						/>
					)
				})}
			</Map>
			<NoDisplayMobile>
				<MapFooter flexDirection="row" alignItems="center" justifyContent="space-between">
					<Box>
						<Flex alignItems="center" justifyContent="center" flexDirection="row" flexWrap="no-wrap">
							<ZoomBox
								alignSelf="center"
								onClick={() => {
									setZoom(Math.min(zoom + 1, 18))
								}}
							>
								<ZoomInIcon height={20} width={20} color={COLORS.WHITE} />
							</ZoomBox>
							<ZoomBox
								alignSelf="center"
								onClick={() => {
									setZoom(Math.max(zoom - 1, 1))
								}}
							>
								<ZoomOutIcon height={20} width={20} color={COLORS.WHITE} />
							</ZoomBox>
						</Flex>
					</Box>
					<Box>
						<DispensaryLocator />
					</Box>
				</MapFooter>
			</NoDisplayMobile>
		</>
	)
}
DispensaryMap.propTypes = {
	defaultZoom: PropTypes.number,
	dispensaries: PropTypes.array,
	selectedDispensary: PropTypes.object,
	setSelectedDispensary: PropTypes.func,
	defaultCenter: PropTypes.array,
	isVisible: PropTypes.bool
}

export default DispensaryMap
