import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@rebass/emotion'
import styled from '@emotion/styled'

export const StickyFooterWrapper = styled(Flex)`
	bottom: 0;
	position: fixed;
	height: ${({ verticalHeight = '11vh' }) => verticalHeight};
	width: 100%;
`

function StickyFooter({ children, verticalHeight }) {
	return <StickyFooterWrapper verticalHeight={verticalHeight}>{children}</StickyFooterWrapper>
}

StickyFooter.propTypes = {
	verticalHeight: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
}
export default StickyFooter
